<!--
 * @Author: yzr
 * @Date: 2020-11-24 14:24:20
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-17 15:47:05
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import NoticeManageTableForm from './components';
import SalesArea from './sales_area';
import TargetTable from './target_table';

let timer = null;
formCreate.component('NoticeManageTableForm', NoticeManageTableForm);
formCreate.component('SalesArea', SalesArea);
formCreate.component('TargetTable', TargetTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    parentCode: String,
  },
  data() {
    return {
      rule: [],
      cusName: '',
      cusCode: '',
      cusInfo: {},
      targetType: '',
      // disableComponents: ['cusCode', 'cusOrgName', ''],
      salesArea: '',
      tableData: [],
    };
  },

  async mounted() {
    if (this.parentCode) {
      this.buttons = {
        submit: false,
        close: false,
      };
    }

    const formRule = await this.getFormRule('dms-target-add', this.parentCode);
    this.rule = formRule;

    // 初始化表单
    this.initEditValue();
    const newRule = formRule.map((item) => {
      let obj = item;
      if (item.field === 'objectType') {
        obj = {
          ...item,
          // options: [
          //   {
          //     value: '1',
          //     label: '客户',
          //   },
          //   {
          //     value: '2',
          //     label: '组织',
          //   },
          //   {
          //     value: '3',
          //     label: '终端',
          //   },
          // ],
          on: {
            change: (val) => {
              this.hiddenFields(true, ['cusCode', 'cusName', 'terminalCode', 'terminalName', 'orgCode', 'orgName', 'salesAreaCode']);
              if (val === '1') {
                this.hiddenFields(false, ['cusCode', 'cusName', 'salesAreaCode']);
              } else if (val === '2') {
                this.hiddenFields(false, ['orgCode', 'orgName']);
              } else if (val === '3') {
                this.hiddenFields(false, ['terminalCode', 'terminalName']);
              }
            },
          },
        };
      }
      if (item.field === 'cusName') {
        obj = {
          ...item,
          props: {
            name: '',
            functionCode: 'dms-target-customer',
            placeholder: item.title,
            edit: this.formConfig.edit,
          },
          validate: [
            { required: true, type: 'string', message: '客户名称不能为空' },
          ],
          on: {
            setCusName: this.setCusName,
            blur: () => {
              this.fApi.validateField('cusName');
            },
          },
          value: '',
        };
      }
      if (item.field === 'orgName') {
        obj = {
          ...item,
          props: {
            name: '',
            functionCode: 'dms-target-org',
            placeholder: item.title,
            edit: this.formConfig.edit,
          },
          validate: [
            { required: true, type: 'string', message: '组织名称不能为空' },
          ],
          on: {
            setCusName: this.setOrgName,
            blur: () => {
              this.fApi.validateField('cusName');
            },
          },
          value: '',
        };
      }
      if (item.field === 'taskType') {
        obj = {
          ...item,
          on: {
            change: (val) => {
              this.getRule('saleGoalItemVo').props.taskType = val;
            },
          },
          value: '',
        };
      }
      if (item.field === 'terminalName') {
        obj = {
          ...item,
          props: {
            name: '',
            functionCode: 'dms-target-terminal',
            placeholder: item.title,
            edit: this.formConfig.edit,
          },
          validate: [
            { required: true, type: 'string', message: '终端名称不能为空' },
          ],
          on: {
            setCusName: this.setTerminalName,
            blur: () => {
              this.fApi.validateField('cusName');
            },
          },
          value: '',
        };
      }

      if (item.field === 'salesAreaCode') {
        obj = {
          ...item,
          type: 'SalesArea',
          props: {
            customerCode: this.cusCode,
            salesAreaCode: this.salesArea,
            disabled: true,
          },
          on: {
            setSalesArea: this.setSalesArea,
          },
          value: {},
        };
      }
      if (item.field === 'type') {
        this.getSalesType();
      }

      if (item.field === 'saleGoalItemVo') {
        obj = {
          ...item,
          type: 'TargetTable',
          props: {
            targetType: '99',
            taskType: '',
            edit: this.formConfig.edit,
            tableData: this.tableData,
            parentCode: this.parentCode,
          },
          on: {
            setTableData: this.setTableData,
          },
          value: '',
        };
      }
      if (item.field === 'terminalCode' || item.field === 'cusCode' || item.field === 'orgCode') {
        obj = {
          ...item,
          props: {
            disabled: true,
          },
        };
      }
      obj.props = {
        ...obj.props,
        parentCode: this.parentCode,
      };
      return obj;
    });

    this.rule = newRule;
    this.reload(this.rule);

    // 未选择客户则禁用
    // if (this.formConfig.edit) {
    //   this.disableComponents.push('type');
    // }
    // this.disableComponents.map((item) => {
    //   this.getRule(item).props.disabled = true;
    //   return true;
    // });

    this.getRule('type').on.change = this.targetChange;
    this.hiddenFields(true, ['cusCode', 'cusName', 'terminalCode', 'terminalName', 'orgCode', 'orgName', 'salesAreaCode']);
    // this.setParentValue();
  },

  methods: {
    setParentValue() {

    },
    formComplete() {

    },
    // 选多销售区域
    setSalesArea(val) {
      this.salesArea = val;
    },

    // 获取价格类型
    getSalesType() {
      const { edit } = this.formConfig;
      if (!edit) {
        request.post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'salesTarget_type',
        }).then((res) => {
          const type = res.result[0].dictCode;
          this.getRule('type').options = res.result.map((v) => ({
            label: v.dictValue,
            value: v.dictCode,
          }));
          this.setValue({
            type,
          });
          this.targetChange(type);
        });
      }
    },

    setCusName(val) {
      this.cusName = val.customerName;
      this.cusCode = val.customerCode;
      this.cusInfo = val;
      this.getRule('cusName').props.name = val.customerName;
      this.setValue({
        cusName: val.customerName,
        cusCode: val.customerCode,
        cusChannelCode: val.channel,
        cusChannelName: val.channelName,
        cusOrgName: val.orgName,
        cusOrgCode: val.orgCode,
      });
      this.getRule('salesAreaCode').props.customerCode = this.cusCode;
      this.getRule('salesAreaCode').props.disabled = false;
      this.reload();
    },
    setOrgName(val) {
      this.getRule('orgName').props.name = val.orgName;
      this.setValue({
        orgName: val.orgName,
        orgCode: val.orgCode,
      });
    },
    setTerminalName(val) {
      this.getRule('terminalName').props.name = val.terminalName;
      this.setValue({
        terminalName: val.terminalName,
        terminalCode: val.terminalCode,
      });
    },

    // 获取表单数据
    setTableData(val) {
      this.tableData = val;
    },

    // 销售目标类型改变
    targetChange(val) {
      this.getRule('saleGoalItemVo').props.targetType = val;
    },

    submit() {
      // if (!this.cusName) {
      //   // this.$message.error('请选择客户名称');
      //   return;
      // }
      // console.log(this.tableData);

      if (!this.tableData.length) {
        this.$message.error('销售目标不能为空！');
        return;
      }

      const formData = this.getFormData();

      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        if (formData) this.submitForm();
      }, 500);
    },

    setTabData() {
      const { edit } = this.formConfig;
      let tabData = [];

      tabData = this.tableData.map((item) => {
        const data = item;

        const params = {
          ...data,
          ...this.getFormData(),
          salesAreaCode: this.salesArea,
          goodsName: data.productName,
          goodsCode: data.productCode,
          saleGoalItemVo: {
            ...data,
          },
        };
        if (data.saleGoalId) {
          params.id = data.saleGoalId;
        }

        // if (edit) {
        //   data.id = this.formConfig.id;

        //   if (!this.cusName) {
        //     data.cusName = this.formConfig.cusName;
        //     data.cusCode = this.formConfig.cusCode;
        //   } else {
        //     data.cusName = this.cusName;
        //     data.cusCode = this.cusCode;
        //   }
        // } else {
        //   data.cusName = this.cusName;
        //   data.cusCode = this.cusCode;
        // }
        // console.log(data);
        return params;
      });
      return tabData;
    },

    // 提交表单
    submitForm() {
      const { edit } = this.formConfig;
      let requestUrl = '/dms/m/saleGoal/addBatch';
      if (edit) {
        requestUrl = '/dms/m/saleGoal/edit';
      }
      const tabData = this.setTabData();
      const params = edit ? tabData[0] : tabData;
      // console.log('params', params);
      request.post(requestUrl, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },

    // 编辑赋值
    initEditValue() {
      const { edit } = this.formConfig;
      if (!edit) {
        this.resetFields();
      } else {
        this.getInfo(this.formConfig.id);
        this.cusName = this.formConfig.cusName;
        this.cusCode = this.formConfig.cusCode;
      }
    },

    // 通过id查询销售目标信息
    getInfo(id) {
      request.get(`/dms/m/saleGoal/findById/${id}`).then((res) => {
        const { result } = res;
        this.setInfoValue(result);
        // this.getRule('salesAreaCode').props.disabled = false;
      });
    },
    setInfoValue(result) {
      this.setValue({
        ...result,
        taskType: result.taskType.toString(),
        objectType: result.objectType.toString() || '1',
        type: result.type.toString(),
      });
      this.salesArea = result.salesAreaCode;
      this.tableData = [{
        ...result.saleGoalItemVo,
        targetNum: result.targetNum,
        targetYear: result.targetYear,
        productCode: result.goodsCode,
        productName: result.goodsName,
        productLevelCode: result.productLevelCode,
        productLevelName: result.productLevelName,
      }];
      if (result.objectType.toString() === '1') {
        this.hiddenFields(false, ['cusCode', 'cusName', 'salesAreaCode']);
      } else if (result.objectType.toString() === '2') {
        this.hiddenFields(false, ['orgCode', 'orgName']);
      } else if (result.objectType.toString() === '3') {
        this.hiddenFields(false, ['terminalCode', 'terminalName']);
      }
      this.getRule('saleGoalItemVo').props.tableData = this.tableData;
      this.getRule('saleGoalItemVo').props.targetType = result.type.toString();
      this.getRule('saleGoalItemVo').props.taskType = result.taskType.toString();
      this.getRule('saleGoalItemVo').props.edit = true;
      this.getRule('salesAreaCode').props.salesAreaCode = result.salesAreaCode;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .form-create .form-create .el-form-item .el-form-item{
  margin-bottom: 18px !important;
}
</style>
