var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { placeholder: "请选择", disabled: _vm.disabled },
      on: {
        focus: function ($event) {
          return _vm.getOptions(_vm.customerCode)
        },
        change: _vm.selectChange,
      },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }