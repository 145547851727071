<!--
 * @Author: yzr
 * @Date: 2020-11-29 15:47:22
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-09 18:00:58
-->
<template>
  <div>
    <div>
      <vxe-toolbar v-if="!edit || contractEdit">
        <template v-slot:buttons>
          <vxe-button @click="insertEvent()">新增</vxe-button>
          <vxe-button @click="delRow">删除</vxe-button>
          <i v-if="isEmpty"
             class="el-icon-warning warningIcon">销售目标不能为空！</i>
        </template>
      </vxe-toolbar>
      <vxe-table border
                 ref="xTable"
                 row-id="$rowIndex"
                 show-header-overflow
                 show-overflow
                 highlight-hover-row
                 :align="allAlign"
                 :data="tableInfo.data"
                 show-footer
                 :footer-method="footerMethod"
                 :edit-config="{ trigger: 'click', mode: 'row' }">
        <vxe-table-column type="seq"
                          title="序号"
                          width="60"
                          fixed="left"></vxe-table-column>
        <vxe-table-column type="checkbox"
                          width="40"
                          fixed="left"></vxe-table-column>
        <vxe-table-column v-for="(col) in tableColumns"
                          :key="col.field"
                          :field="col.field"
                          minWidth="100"
                          :width='col.width'
                          :title="col.title"
                          :edit-render="col.editRender
            && {...col.editRender,
            props : {...col.editRender.props ,
            disabled: tableDisable(edit , col.field)}}"
                          :min-width="col.width ? col.width : 80"
                          :fixed='col.fixed'>
          <!-- <template v-slot:default="{row , rowIndex}"
                    v-if="col.field === 'targetNum'">
            {{countAmount(row , rowIndex)}}
          </template> -->
        </vxe-table-column>
      </vxe-table>
    </div>
    <!-- 弹窗 -->
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'TargetTable',
  props: {
    targetType: String,
    taskType: String,
    edit: String,
    isInit: Boolean,
    contractEdit: Boolean,
    tableData: Array,
    parentCode: String,
  },
  components: {
    SelectConfig,
  },
  watch: {
    // tableData: {
    //   deep: true,
    //   immediate: true,
    //   handler(v) {
    //     if (v) { this.tableInfo.data = v; }
    //   },
    // },
    // 任务类型变更
    taskType(n) {
      let functionCode = '';
      if (this.isInitTable && this.edit) {
        this.tableInfo.data = this.tableData;
        this.isInitTable = false;
      } else {
        // 清空数据
        const plusKey = [
          'yearlyNum',
          'targetNum',
          'firstQuarterNum',
          'secondQuarterNum',
          'thirdQuarterNum',
          'fourthQuarterNum',
          'febTargetNum',
          'janTargetNum',
          'marTargetNum',
          'aprTargetNum',
          'mayTargetNum',
          'junTargetNum',
          'julTargetNum',
          'augTargetNum',
          'sepTargetNum',
          'octTargetNum',
          'novTargetNum',
          'decTargetNum',
        ];
        this.$refs.xTable.getTableData().tableData.map((v) => {
          const rowData = v;
          plusKey.forEach((a) => {
            rowData[a] = 0;
          });
          return rowData;
        });
      }
      // 根据任务类型设置配置code
      switch (n) {
        case '1': functionCode = 'target_table_year'; break;
        case '2': functionCode = 'target_table_quarter'; break;
        case '3': functionCode = 'target_table_month'; break;
        default:
          break;
      }
      const params = {
        function_code: functionCode,
        functionCode,
        parentCode: this.parentCode || this.$store.state.menus.selectMenu,
      };
      if (functionCode) {
        request.post('/mdm/mdmtableconfig/query', params).then((res) => {
          if (res.success) {
            this.rightColumns = [];
            this.tableColumns = [];
            res.result.column.forEach((v) => {
              const rowData = v;
              rowData.editRender = { name: '$input', props: { type: 'number', min: 0 } };
              this.rightColumns.push(rowData);
              this.tableField[rowData.field] = 0;
            });
            // 重组列
            this.$nextTick(() => {
              this.tableColumns = this.leftColumns.concat(this.rightColumns);
            });
          }
        });
      } else {
        this.tableColumns = [];
        this.rightColumns = [];

        // 重组列
        this.$nextTick(() => {
          this.tableColumns = this.leftColumns;
        });
      }
    },
    // 销售目标类型变更
    targetType(n) {
      // 清空数据
      const plusKey = [
        'productName',
        'productCode',
        'productLevelName',
        'productLevelCode',
      ];
      this.$refs.xTable.getTableData().tableData.map((v) => {
        const rowData = v;
        plusKey.forEach((a) => {
          rowData[a] = '';
        });
        return rowData;
      });

      // 根据任务类型设置配置code
      const params = {
        function_code: `target_table_${n}`,
        functionCode: `target_table_${n}`,
        parentCode: this.parentCode || this.$store.state.menus.selectMenu,
      };
      request.post('/mdm/mdmtableconfig/query', params).then((res) => {
        if (res.success) {
          this.tableColumns = [];
          this.leftColumns = [];
          res.result.column.forEach((v) => {
            const rowData = v;
            rowData.fixed = 'left';
            if (v.formControl) {
              rowData.editRender = {
                name: `$${v.formControl}`,
                props: { readonly: true },
                events: { focus: this.openModal },
              };
            }
            this.leftColumns.push(rowData);
            this.tableField[rowData.field] = '';
          });
          this.tableInfo.columns.forEach((v) => {
            const rowData = v;
            // 设置年总量列的编辑是否可用，enabled
            // if (rowData.field === 'targetNum') {
            //   rowData.editRender = { name: '$input', props: { type: 'number', min: 0 }, enabled: this.rightColumns.length === 0 };
            // }
            this.leftColumns.push(rowData);
            this.tableField[v.field] = v.value || 0;
          });

          // 重组列
          this.tableColumns = this.leftColumns.concat(this.rightColumns);
        }
      });
    },
  },

  data() {
    return {
      allAlign: null,
      isInitTable: true,
      rowIndex: '',
      isEmpty: false,
      tableColumns: [], // 列配置
      leftColumns: [], // 销售目标类型列和年总量等固定列配置
      rightColumns: [], // 任务类型列配置
      tableField: {}, // 列表字段
      tableInfo: {
        columns: [
          {
            fixed: 'left',
            field: 'targetYear',
            title: '年度',
            value: '2021',
            editRender: { name: '$input', props: { type: 'number', min: 0 } },
          },
          {
            fixed: 'left',
            field: 'targetNum',
            value: '0',
            title: '年总量',
            // editRender: { name: '$input', props: { type: 'number', min: 0 }, enabled: true },
          },
        ],
        data: [
          // 默认初始数据
          {
            productName: '',
            productCode: '',
            productLevelName: '',
            productLevelCode: '',
            targetYear: '2021',
            targetNum: 0,
            yearlyNum: 0,
            febTargetNum: 0,
            janTargetNum: 0,
            marTargetNum: 0,
            aprTargetNum: 0,
            mayTargetNum: 0,
            junTargetNum: 0,
            julTargetNum: 0,
            augTargetNum: 0,
            sepTargetNum: 0,
            octTargetNum: 0,
            novTargetNum: 0,
            decTargetNum: 0,
            firstQuarterNum: 0,
            secondQuarterNum: 0,
            thirdQuarterNum: 0,
            fourthQuarterNum: 0,
          },
        ],
      },
    };
  },
  mounted() {
  },
  methods: {
    tableDisable(edit, field) {
      if (edit === 'view') {
        return true;
      }
      if (edit === 'edit') {
        if (field === 'productName' || field === 'productLevelName') {
          return true;
        }
      }
      return false;
    },

    // 新增一行
    async insertEvent(row) {
      const newRow = await this.$refs.xTable.insertAt(this.tableField, row);
      await this.$refs.xTable.setActiveCell(newRow, 'sex');
    },

    // 弹窗回调
    onGetSelect(val) {
      const { fullData } = this.$refs.xTable.getTableData();
      if (this.targetType === '1') {
        fullData[this.rowIndex].productName = val[0].productName;
        fullData[this.rowIndex].productCode = val[0].productCode;
        fullData[this.rowIndex].productSpec = val[0].spec;
        fullData[this.rowIndex].productLevelCode = val[0].productLevelCode;
        fullData[this.rowIndex].productLevelName = val[0].productLevelName;
      }

      if (this.targetType === '2') {
        fullData[this.rowIndex].productLevelName = val[0].productLevelName;
        fullData[this.rowIndex].productLevelCode = val[0].productLevelCode;
      }
    },

    // 打开弹窗
    openModal(val) {
      this.rowIndex = val.$rowIndex;

      const params = {
        functionCode: 'dms-target-products',
        data: [],
        selectionList: [],
        idKey: 'productCode',
        parentCode: this.parentCode || this.$store.state.menus.selectMenu,
      };

      if (this.targetType === '2') {
        params.functionCode = 'dms-target-productLevel';
        params.idKey = 'productLevelCode';
      }
      if (!this.edit || this.contractEdit) {
        this.$refs.selectConfig.openSelectModal(params);
      }
    },

    delRow() {
      this.$refs.xTable.removeCheckboxRow();
    },
    // 计算
    countAmount(row) {
      // 需要计算的字段
      let plusKey = [
        // 'targetNum',
        'yearlyNum',
        'firstQuarterNum',
        'secondQuarterNum',
        'thirdQuarterNum',
        'fourthQuarterNum',
        'febTargetNum',
        'janTargetNum',
        'marTargetNum',
        'aprTargetNum',
        'mayTargetNum',
        'junTargetNum',
        'julTargetNum',
        'augTargetNum',
        'sepTargetNum',
        'octTargetNum',
        'novTargetNum',
        'decTargetNum',
      ];
      if (this.rightColumns.length === 0) {
        plusKey = ['targetNum'];
      }
      let targetNum = 0;
      plusKey.forEach((val) => {
        targetNum = this.accAdd(targetNum, parseFloat(row[val] || 0));
      });

      // eslint-disable-next-line no-param-reassign
      row.targetNum = targetNum;
      const { tableData } = this.$refs.xTable.getTableData();
      this.$emit('setTableData', tableData);
      return targetNum;
    },
    countAllAmount(data) {
      let count = 0;
      data.forEach((v) => (count = this.accAdd(count, this.countAmount(v))));
      return count;
    },

    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          let isCount = true;
          switch (column.property) {
            case 'productName':
              isCount = false;
              break;
            case 'productCode':
              isCount = false;
              break;
            case 'targetYear':
              isCount = false;
              break;
            case 'productLevelName':
              isCount = false;
              break;
            case 'productLevelCode':
              isCount = false;
              break;

            default:
              isCount = true;
              break;
          }
          if (!isCount || columnIndex === 1) {
            return '-';
          }
          if (columnIndex === 0) {
            return '合计';
          }
          if (column.property === 'targetNum') {
            return this.countAllAmount(data);
          }
          let count = 0;
          data.forEach((v) => {
            if (v[column.property] > 0) {
              count = this.accAdd(count, parseFloat(v[column.property]));
            }
          });
          return count;
          // return '-';
        }),
      ];
    },
    // 两个浮点数求和
    accAdd(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // return (num1*m+num2*m)/m;
      return Math.round(num1 * m + num2 * m) / m;
    },
  },
};
</script>

<style lang='less' scoped>
.warningIcon {
  font-size: 14px;
  margin-left: 10px;
  color: red;
}
/deep/ .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
  margin-bottom: 18px !important;
}
</style>
