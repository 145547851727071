<!--
 * @Author: yzr
 * @Date: 2020-12-01 12:57:36
 * @LastEditors: yzr
 * @LastEditTime: 2020-12-03 15:30:25
-->
<template>
<el-select v-model="value" placeholder="请选择" @focus="getOptions(customerCode)" @change="selectChange" :disabled='disabled'>
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'SalesArea',
  props: {
    customerCode: String,
    salesAreaCode: String,
    disabled: Boolean,
    parentCode: String,
  },
  watch: {
    customerCode(nv) {
      console.log('cusCode', nv);
      this.value = null;
    },
    salesAreaCode(nv) {
      this.value = nv;
    },
    disabled(nv) {
      console.log('disabled', nv);
    },
  },
  data() {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕',
      }, {
        value: '选项2',
        label: '双皮奶',
      }, {
        value: '选项3',
        label: '蚵仔煎',
      }, {
        value: '选项4',
        label: '龙须面',
      }, {
        value: '选项5',
        label: '北京烤鸭',
      }],
      value: this.salesAreaCode,
    };
  },

  mounted() {
    this.getOptions(this.customerCode);
  },

  methods: {
    selectChange(val) {
      this.$emit('setSalesArea', val);
    },
    getOptions(customerCode) {
      this.options = [];
      request.get('/mdm/mdmCustomerMsgController/query', { customerCode }).then((res) => {
        const list = res.result ? res.result.mdmCustomerSaleAreaVos : [];
        list.map((item) => {
          const data = item;
          data.label = `${data.areaOneName}${data.areaTwoName ? ` / ${data.areaTwoName}` : ''}${data.areaThreeName ? ` / ${data.areaThreeName}` : ''}`;
          let value = '';
          if (data.areaThree) value = data.areaThree;
          else if (data.areaTwo) value = data.areaTwo;
          else value = data.areaOne;
          data.value = value;

          return data;
        });

        this.options = list;
      });
    },
  },
};
</script>
