var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.openModal } },
    [
      _c("el-input", {
        attrs: {
          placeholder: _vm.placeholder,
          value: _vm.name || _vm.value,
          disabled: !!_vm.edit,
          readonly: "",
        },
        on: { blur: _vm.blurInput },
      }),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }